// ----------------------------------------------------------------------

export const _pricingHome = [
  {
    license: 'Standard',
    price: '59',
    icons: ['/assets/icons/platforms/ic_js.svg'],
    commons: ['One end products', '12 months updates', '6 months of support'],
    options: [
      { title: 'JavaScript version', disabled: false },
      { title: 'TypeScript version', disabled: true },
      { title: 'Design resources', disabled: true },
      { title: 'Commercial applications', disabled: true },
    ],
  },
  {
    license: 'Plus',
    price: '99',
    icons: [
      '/assets/icons/platforms/ic_js.svg',
      '/assets/icons/platforms/ic_ts.svg',
      '/assets/icons/platforms/ic_figma.svg',
    ],
    commons: ['One end products', '12 months updates', '6 months of support'],
    options: [
      { title: 'JavaScript version', disabled: false },
      { title: 'TypeScript version', disabled: false },
      { title: 'Design resources', disabled: false },
      { title: 'Commercial applications', disabled: true },
    ],
  },
  {
    license: 'Extended',
    price: '249',
    icons: [
      '/assets/icons/platforms/ic_js.svg',
      '/assets/icons/platforms/ic_ts.svg',
      '/assets/icons/platforms/ic_figma.svg',
    ],
    commons: ['One end products', '12 months updates', '6 months of support'],
    options: [
      { title: 'JavaScript version', disabled: false },
      { title: 'TypeScript version', disabled: false },
      { title: 'Design resources', disabled: false },
      { title: 'Commercial applications', disabled: false },
    ],
  },
];

export const _pricingMarketing = [
  {
    license: 'Basic',
    price: '29',
    icon: '/assets/icons/pricing/ic_plan_basic03.svg',
    caption: 'Proin viverra, ligula sit amet ultrices semper, ligula arcu tristique sapien',
    options: ['Auto update mode', 'Online operator 24/7', 'International posting'],
  },
  {
    license: 'Starter',
    price: '59',
    icon: '/assets/icons/pricing/ic_plan_starter03.svg',
    caption: 'Sed lectus. Sed consequat, leo eget bibendum sodales',
    options: [
      'Auto update mode',
      'Online operator 24/7',
      'Unique newsletters',
      'International posting',
    ],
  },
  {
    license: 'Premium',
    price: '99',
    icon: '/assets/icons/pricing/ic_plan_premium03.svg',
    caption: 'Maecenas nec odio et ante tincidunt tempus.',
    options: [
      'Auto update mode',
      'Online operator 24/7',
      'Unique newsletters',
      'International posting',
      '20 Design templates',
    ],
  },
];

export enum PricingOptions {
  Check = 'check',
  Number = 'number',
  Text = 'text'
}
export const _pricing01 = [
  {
    license: 'Basic',
    price: 'Free',
    icon: '/assets/icons/pricing/ic_plan_basic01.svg',
    options: [
      { title: 'Budget Planner', value: {type: PricingOptions.Check, value: true} },
      { title: 'Budget and KPI Forecasting', value: {type: PricingOptions.Check, value: true} },
      { title: 'Multiple Scenarios Management', value: {type: PricingOptions.Check, value: true} },
      { title: 'Automatic Budget Monitoring',value: {type: PricingOptions.Check, value: true} },
      { title: 'Plans vs Actual Tracking',value: {type: PricingOptions.Check, value: true} },
      { title: 'Marketing Channels',value: {type: PricingOptions.Number, value: 2} },
      { title: 'CRM platform',value: {type: PricingOptions.Number, value: 1} },
      { title: 'Team Members',value: {type: PricingOptions.Number, value: 1} },
      { title: 'Support',value: {type: PricingOptions.Text, value: 'Regular'} },
    ],
    buttonLabel: 'Basic'
  },
  
  {
    license: 'Starter',
    price: '4.99',
    icon: '/assets/icons/pricing/ic_plan_starter01.svg',
    options: [
      { title: 'Budget Planner', value: {type: PricingOptions.Check, value: true} },
      { title: 'Budget and KPI Forecasting', value: {type: PricingOptions.Check, value: true} },
      { title: 'Multiple Scenarios Management', value: {type: PricingOptions.Check, value: true} },
      { title: 'Automatic Budget Monitoring',value: {type: PricingOptions.Check, value: true} },
      { title: 'Plans vs Actual Tracking',value: {type: PricingOptions.Check, value: true} },
      { title: 'Marketing Channels',value: {type: PricingOptions.Number, value: 5} },
      { title: 'CRM platform',value: {type: PricingOptions.Number, value: 2} },
      { title: 'Team Members',value: {type: PricingOptions.Number, value: 2} },
      { title: 'Support',value: {type: PricingOptions.Text, value: 'Premium'} },
    ],
    buttonLabel: 'Advanced'
  },
  {
    license: 'Premium',
    price: '9.99',
    icon: '/assets/icons/pricing/ic_plan_premium01.svg',
    options: [
      { title: 'Budget Planner', value: {type: PricingOptions.Check, value: true} },
      { title: 'Budget and KPI Forecasting', value: {type: PricingOptions.Check, value: true} },
      { title: 'Multiple Scenarios Management', value: {type: PricingOptions.Check, value: true} },
      { title: 'Automatic Budget Monitoring',value: {type: PricingOptions.Check, value: true} },
      { title: 'Plans vs Actual Tracking',value: {type: PricingOptions.Check, value: true} },
      { title: 'Marketing Channels',value: {type: PricingOptions.Text, value: 'unlimited'} },
      { title: 'CRM platform',value: {type: PricingOptions.Text, value: 'unlimited'} },
      { title: 'Team Members',value: {type: PricingOptions.Number, value: 10} },
      { title: 'Support',value: {type: PricingOptions.Text, value: 'Dedicate'} },
    ],
    buttonLabel: 'Pro'
  },
];
export type Pricing01 = typeof _pricing01[1];

export const annualPricing = [
  {
    license: 'Starter',
    caption: '____',
    price: 'Free',
    icon: '/assets/icons/pricing/ic_plan_basic02.svg',
    options: [
      { title: 'Integration', tootip: 'Integration', disabled: true },
      { title: 'Scenarios 10', tootip: 'Scenarios 10', disabled: false },
      {
        title: 'Save & Export',
        tootip: 'Save & Export',
        disabled: true,
      },
      {
        title: 'Flexible inputs',
        tootip: 'Flexible inputs',
        disabled: true,
      },
      {
        title: 'Inputs - Manual',
        tootip: 'Inputs - Manual',
        disabled: false,
      },
      { title: 'Customers - Marketing/Growth', tootip: 'Customers - Marketing/Growth', disabled: false },
      {
        title: 'Value - Budget plan & pLTV',
        tootip: 'Value - Budget plan & pLTV',
        disabled: false,
      }
    ],
  },
  {
    license: 'Standard',
    caption: 'Billed annually',
    price: '16',
    icon: '/assets/icons/pricing/ic_plan_starter02.svg',
    options: [
      { title: 'Integration', tootip: 'Integration', disabled: true },
      { title: 'Scenarios 10', tootip: 'Scenarios 10', disabled: false },
      {
        title: 'Save & Export',
        tootip: 'Save & Export',
        disabled: false,
      },
      {
        title: 'Flexible inputs',
        tootip: 'Flexible inputs',
        disabled: false,
      },
      {
        title: 'Inputs - Manual',
        tootip: 'Inputs - Manual',
        disabled: false,
      },
      { title: 'Customers - Marketing/Growth', tootip: 'Customers - Marketing/Growth', disabled: false },
      {
        title: 'Value - Budget plan & pLTV',
        tootip: 'Value - Budget plan & pLTV',
        disabled: false,
      }
    ],
  },
  {
    license: 'Advanced',
    caption: 'Billed annually',
    price: '240',
    icon: '/assets/icons/pricing/ic_plan_premium02.svg',
    options: [
      { title: 'Integration', tootip: 'Integration', disabled: false },
      { title: 'Scenarios 10', tootip: 'Scenarios 10', disabled: false },
      {
        title: 'Save & Export',
        tootip: 'Save & Export',
        disabled: false,
      },
      {
        title: 'Flexible inputs',
        tootip: 'Flexible inputs',
        disabled: false,
      },
      {
        title: 'Inputs - Manual',
        tootip: 'Inputs - Manual',
        disabled: false,
      },
      {
        title: 'Customers - Marketing/Growth',
        tootip: 'Customers - Marketing/Growth',
        disabled: false,
      },
      {
        title: 'Value - Budget plan & pLTV',
        tootip: 'Value - Budget plan & pLTV',
        disabled: false,
      }
    ],
  },
  {
    license: 'Pro',
    caption: 'Billed annually',
    price: 'Custom',
    icon: '/assets/icons/pricing/ic_plan_premium02.svg',
    options: [
      { title: 'Integration', tootip: 'Integration', disabled: false },
      { title: 'Scenarios 10', tootip: 'Scenarios 10', disabled: false },
      {
        title: 'Save & Export',
        tootip: 'Save & Export',
        disabled: false,
      },
      {
        title: 'Flexible inputs',
        tootip: 'Flexible inputs',
        disabled: false,
      },
      {
        title: 'Inputs - Manual',
        tootip: 'Inputs - Manual',
        disabled: false,
      },
      {
        title: 'Customers - Marketing/Growth',
        tootip: 'Customers - Marketing/Growth',
        disabled: false,
      },
      {
        title: 'Value - Budget plan & pLTV',
        tootip: 'Value - Budget plan & pLTV',
        disabled: false,
      }
    ],
  },
];
export const _pricing02 = [
  {
    license: 'Starter',
    caption: '____',
    price: 'Free',
    icon: '/assets/icons/pricing/ic_plan_basic02.svg',
    options: [
      { title: 'Integration', tootip: 'Integration', disabled: true },
      { title: 'Scenarios 10', tootip: 'Scenarios 10', disabled: false },
      {
        title: 'Save & Export',
        tootip: 'Save & Export',
        disabled: true,
      },
      {
        title: 'Flexible inputs',
        tootip: 'Flexible inputs',
        disabled: true,
      },
      {
        title: 'Inputs - Manual',
        tootip: 'Inputs - Manual',
        disabled: false,
      },
      { title: 'Customers - Marketing/Growth', tootip: 'Customers - Marketing/Growth', disabled: false },
      {
        title: 'Value - Budget plan & pLTV',
        tootip: 'Value - Budget plan & pLTV',
        disabled: false,
      }
    ],
  },
  {
    license: 'Standard',
    caption: 'Billed monthly',
    price: '20',
    icon: '/assets/icons/pricing/ic_plan_starter02.svg',
    options: [
      { title: 'Integration', tootip: 'Integration', disabled: true },
      { title: 'Scenarios 10', tootip: 'Scenarios 10', disabled: false },
      {
        title: 'Save & Export',
        tootip: 'Save & Export',
        disabled: false,
      },
      {
        title: 'Flexible inputs',
        tootip: 'Flexible inputs',
        disabled: false,
      },
      {
        title: 'Inputs - Manual',
        tootip: 'Inputs - Manual',
        disabled: false,
      },
      { title: 'Customers - Marketing/Growth', tootip: 'Customers - Marketing/Growth', disabled: false },
      {
        title: 'Value - Budget plan & pLTV',
        tootip: 'Value - Budget plan & pLTV',
        disabled: false,
      }
    ],
  },
  {
    license: 'Advanced',
    caption: 'Billed monthly',
    price: '300',
    icon: '/assets/icons/pricing/ic_plan_premium02.svg',
    options: [
      { title: 'Integration', tootip: 'Integration', disabled: false },
      { title: 'Scenarios 10', tootip: 'Scenarios 10', disabled: false },
      {
        title: 'Save & Export',
        tootip: 'Save & Export',
        disabled: false,
      },
      {
        title: 'Flexible inputs',
        tootip: 'Flexible inputs',
        disabled: false,
      },
      {
        title: 'Inputs - Manual',
        tootip: 'Inputs - Manual',
        disabled: false,
      },
      {
        title: 'Customers - Marketing/Growth',
        tootip: 'Customers - Marketing/Growth',
        disabled: false,
      },
      {
        title: 'Value - Budget plan & pLTV',
        tootip: 'Value - Budget plan & pLTV',
        disabled: false,
      }
    ],
  },
  {
    license: 'Pro',
    caption: 'Billed monthly',
    price: 'Custom',
    icon: '/assets/icons/pricing/ic_plan_premium02.svg',
    options: [
      { title: 'Integration', tootip: 'Integration', disabled: false },
      { title: 'Scenarios 10', tootip: 'Scenarios 10', disabled: false },
      {
        title: 'Save & Export',
        tootip: 'Save & Export',
        disabled: false,
      },
      {
        title: 'Flexible inputs',
        tootip: 'Flexible inputs',
        disabled: false,
      },
      {
        title: 'Inputs - Manual',
        tootip: 'Inputs - Manual',
        disabled: false,
      },
      {
        title: 'Customers - Marketing/Growth',
        tootip: 'Customers - Marketing/Growth',
        disabled: false,
      },
      {
        title: 'Value - Budget plan & pLTV',
        tootip: 'Value - Budget plan & pLTV',
        disabled: false,
      }
    ],
  },
];

export type Pricing = typeof _pricing02;
