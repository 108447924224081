import { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import MainLayout from 'src/layouts/main';
import { SplashScreen } from 'src/components/loading-screen';

import { authRoutes } from './auth';
import { errorRoutes } from './error';
import { travelRoutes } from './travel';
import { commonRoutes } from './common';
import { careerRoutes } from './career';
import { eLearningRoutes } from './elearning';
import { marketingRoutes } from './marketing';
import { eCommerceRoutes } from './ecommerce';
import { componentsRoutes } from './components';
import HubspotSourcePage from 'src/pages/funnelsite/views/HubspotSource';
import IntegrationLayout from 'src/layouts/integration';
import BudgetPlannerLanding from 'src/pages/funnelsite/BudgetPlannerLanding';
import LandingPageNew from 'src/pages/funnelsite/views/LandingPageNew';
// ----------------------------------------------------------------------

// const IndexPage = lazy(() => import('src/pages/home'));
const IndexPage = lazy(() => import('src/pages/funnelsite/HomePage'));
const Services = lazy(() => import('src/pages/funnelsite/Services'));
const Pricing02Page = lazy(() => import('src/pages/pricing-02'));
const SupportPage = lazy(() => import('src/pages/support'));
// const ContactPage = lazy(() => import('src/pages/marketing/contact'));
const ContactPage = lazy(() => import('src/pages/funnelsite/ContactUsPageWrapper'));
const AboutUsPage = lazy(() => import('src/pages/funnelsite/AboutUsPage'));
const TermAndConditionsPage = lazy(() => import('src/pages/funnelsite/TermAndConditionsPage'));
const PrivacyPolicyPage = lazy(() => import('src/pages/funnelsite/PrivacyPolicyPage'));
const SubscriptionAndRefundPage = lazy(() => import('src/pages/funnelsite/SubscriptionAndRefund'));
const IntegrationPage = lazy(() => import('src/pages/funnelsite/IntegrationPage'));
const HubspotIntegrationPage = lazy(() => import('src/pages/funnelsite/views/HubspotSource'));
const SourceComingSoon = lazy(() => import('src/pages/funnelsite/views/SourceComingSoon'));

export default function Router() {
  return useRoutes([
    {
      element: (
        // <MainLayout>
        <>
          <Suspense fallback={<SplashScreen />}>
            <Outlet />
          </Suspense>
        </>
        // </MainLayout>
      ),
      children: [
        { element: <IndexPage />, index: true },
        { path: 'support', element: <SupportPage /> },
        { path: 'platform', element: <Services /> },
        { path: 'pricing', element: <Pricing02Page /> },
        { path: 'contact', element: <ContactPage /> },
        { path: 'about', element: <AboutUsPage /> },
        { path: 'terms', element: <TermAndConditionsPage /> },
        { path: 'privacy', element: <PrivacyPolicyPage /> },
        { path: 'subscription', element: <SubscriptionAndRefundPage /> },

        {
          path: 'integration',
          element: (
            <IntegrationLayout>
              <Outlet />
            </IntegrationLayout>
          ),
          children: [
            { path: 'hubspot', element: <HubspotSourcePage /> },
            { path: 'google-ads', element: <SourceComingSoon /> },
            { path: 'linkedin-ads', element: <SourceComingSoon /> },
            { path: 'facebook-ads', element: <SourceComingSoon /> },
            { path: 'salesforce', element: <SourceComingSoon /> },
          ],
        },

        ...marketingRoutes,

        ...travelRoutes,

        ...careerRoutes,

        ...eLearningRoutes,

        ...eCommerceRoutes,

        ...componentsRoutes,
      ],
    },

    ...authRoutes,

    ...errorRoutes,

    ...commonRoutes,

    { path: '*', element: <Navigate to="/" replace /> },

    { path: 'planner/landing', element: <BudgetPlannerLanding /> },
    { path: 'planner/landing/v2', element: <LandingPageNew /> },
  ]);
}
