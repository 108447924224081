import { alpha, useTheme } from '@mui/material/styles';

import { Box, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
// import Grid from '@mui/material/Unstable_Grid2';
import { HEADER } from 'src/layouts/config-layout';
import Iconify from 'src/components/iconify';
import Image from 'src/components/image';
import Stack from '@mui/material/Stack';
import { Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import { bgGradient } from 'src/theme/css';
import dashboardImage from 'src/assets/funnelLoop/planner3.png';
import dashboardImageNew from 'src/assets/funnelLoop/landing_image_new.png';
import logo from 'src/assets/funnelLoop/logo_png.png';
import saas from 'src/assets/funnelLoop/saas_icon.png';
import styled from '@emotion/styled';
import { useBoundingClientRect } from 'src/hooks/use-bounding-client-rect';
import { useRef } from 'react';
import { useResponsive } from 'src/hooks/use-responsive';

// ----------------------------------------------------------------------

const ImageContainer = styled.div`
  position: absolute;
  max-width: 72%;
  bottom: 5%;
  width: 72%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  z-index: 0;
  right: 0;
  height: 100%;

  @media (max-width: 1700px) {
  }
`;

const redColor = 'rgb(255, 76, 81)';
const yellowColor = 'rgb(224, 158, 0)';
const greenColor = 'rgb(76, 178, 0)';

export default function PlannerLanding() {
  const theme = useTheme();

  const containerRef = useRef<HTMLDivElement>(null);

  const mdUp = useResponsive('up', 'md');

  const mdDown = useResponsive('down', 'md');

  const container = useBoundingClientRect(containerRef);

  const offsetLeft = container?.left;

  //   return (
  //     <Box
  //       sx={{
  //         ...bgGradient({
  //           color: alpha(theme.palette.background.default, 0.9),
  //           imgUrl: '/assets/background/overlay_1.jpg',
  //         }),
  //         overflow: 'hidden',
  //         position: 'relative',
  //         height: { md: `calc(100vh - ${HEADER.H_DESKTOP}px)` },
  //       }}
  //     >
  //       <Container sx={{ height: 1 }}>
  //         <Grid
  //           container
  //           columnSpacing={2}
  //           alignItems="center"
  //           display={'flex'}
  //           flexDirection={'row-reverse'}
  //           zIndex={11}
  //           spacing={2}
  //         >
  //           <Grid item xs={12} md={6}>
  //             <Button
  //               style={{
  //                 backgroundColor: 'rgba(22, 177, 255, 0.85)',
  //                 top: '40px',
  //                 marginLeft: '4px',
  //               }}
  //               color="inherit"
  //               size="large"
  //               variant="contained"
  //               endIcon={<Iconify icon="carbon:launch" />}
  //               target="_blank"
  //               rel="noopener"
  //               href="https://app.funneloop.ai/register?utm_source=funneloop-website&utm_campaign=planner"
  //             >
  //               {'Sign up for Free'}
  //             </Button>
  //           </Grid>
  //           <Grid item xs={12} md={6}>
  //             <Button
  //               style={{
  //                 backgroundColor: yellowColor,
  //                 top: '40px',
  //               }}
  //               color="inherit"
  //               size="large"
  //               variant="contained"
  //               endIcon={<Iconify icon="carbon:launch" />}
  //               target="_blank"
  //               rel="noopener"
  //               href="https://calendly.com/funneloop/15min
  //             "
  //             >
  //               {'Book a Demo'}
  //             </Button>
  //           </Grid>
  //         </Grid>
  //         <Grid container columnSpacing={3} alignItems="center" sx={{ height: 1 }}>
  //           <Grid
  //             xs={12}
  //             sx={{
  //               zIndex: '10',
  //             }}
  //           >
  //             <Stack
  //               spacing={5}
  //               justifyContent="center"
  //               alignItems={{ xs: 'center', md: 'flex-start' }}
  //               sx={{
  //                 py: 15,
  //                 textAlign: { xs: 'center', md: 'left', '@media (min-width:600px)': '1.9rem' },
  //               }}
  //             >
  //               <Typography variant="h1">
  //                 {/* <Box component="span" sx={{ color: redColor }}> */}
  //                 Calculate LTV in
  //                 <Box component="span" sx={{ color: greenColor, fontWeight: 600 }}>
  //                   {` 30 `}
  //                 </Box>{' '}
  //                 seconds
  //                 {/* </Box> */}
  //               </Typography>

  //               <Typography sx={{ color: 'text.secondary' }}>
  //                 <Box component="span" sx={{ color: redColor, fontWeight: 600 }}>
  //                   Don't spend{' '}
  //                 </Box>
  //                 hours on Excel trying to <br />
  //                 <Box component="span" sx={{ color: yellowColor, fontWeight: 600 }}>
  //                   figure out{' '}
  //                 </Box>{' '}
  //                 how to calculate your LTV. <br />
  //                 Let{' '}
  //                 <Box component="span" sx={{ color: greenColor, fontWeight: 600 }}>
  //                   FunneLoop
  //                 </Box>{' '}
  //                 do the work for you.
  //               </Typography>
  //               {/* <Typography sx={{ color: 'text.secondary' }}>Start saving money today!</Typography> */}
  //               <Button
  //                 style={{
  //                   backgroundColor: 'rgba(22, 177, 255, 0.85)',
  //                   top: '140px',
  //                 }}
  //                 color="inherit"
  //                 size="large"
  //                 variant="contained"
  //                 endIcon={<Iconify icon="carbon:launch" />}
  //                 target="_blank"
  //                 rel="noopener"
  //                 href="https://app.funneloop.ai/register?utm_source=funneloop-website&utm_campaign=planner"
  //               >
  //                 {'Sign up for Free'}
  //               </Button>
  //             </Stack>
  //           </Grid>

  //           <Grid xs={12} md={7}>
  //             <Box ref={containerRef} />
  //           </Grid>
  //         </Grid>
  //       </Container>

  //       {mdUp && (
  //         <ImageContainer>
  //           {/* <Image
  //             width="80%"
  //             top="35%"
  //             visibleByDefault
  //             disabledEffect
  //             alt="Budget Planner"
  //             src={dashboardImage}
  //           /> */}
  //           <Box
  //             display={'flex'}
  //             sx={{ position: 'absolute', top: '40%', left: '10%', boxShadow: 3 }}
  //           >
  //             <img
  //               src={dashboardImage}
  //               alt="Budget Planner"
  //               width={700}
  //               style={{
  //                 transform: 'perspective(600px) rotate3D(1, -0.6, 0.4, 15deg)',
  //                 // boxShadow: '0 0 40px 0 rgba(2, 3, 4, 0.3)',
  //                 // mask: 'linear-gradient(135deg, #001c 40%, #020, #080c 60%) 100% 100%/ 240% 240%',
  //               }}
  //             />
  //           </Box>
  //         </ImageContainer>
  //       )}
  //       {/* {mdUp && (
  //              <Box
  //               sx={{
  //                 maxWidth: 1280,
  //                 position: 'absolute',
  //                 bottom: { md: '30%', lg: 190 },
  //                 right: { md: -110, xl: 0 },
  //                 width: { md: `calc(100% - ${offsetLeft}px)` },
  //               }}
  //             >
  //               <Image
  //                 visibleByDefault
  //                 disabledEffect
  //                 alt="marketing market"
  //                 src="/assets/illustrations/illustration_marketing_market.svg"
  //               />
  //             </Box>
  //           )} */}
  //     </Box>
  //   );

  return (
    <Grid container marginTop={4}>
      <Grid item xs={6} md={5} textAlign={'center'}>
        <Box width={150} textAlign={'center'} sx={{ ml: 10 }}>
          <img src={logo} alt="Budget Planner" />
        </Box>
      </Grid>
      <Grid
        item
        xs={6}
        md={6}
        display={'flex'}
        flexDirection={'row-reverse'}
        sx={{ mr: 4 }}
        textAlign={'center'}
        zIndex={11}
      >
        {/* <Button
          style={{
            backgroundColor: 'rgba(22, 177, 255, 0.85)',
            top: '40px',
            marginLeft: '4px',
          }}
          color="inherit"
          size="large"
          variant="contained"
          endIcon={<Iconify icon="carbon:launch" />}
          target="_blank"
          rel="noopener"
          href="https://calendly.com/funneloop/15min
          "
        >
          {'Book a Demo'}
        </Button> */}
        <Button
          style={{
            backgroundColor: greenColor,
            top: '40px',
            marginLeft: '4px',
          }}
          color="inherit"
          size="large"
          variant="contained"
          endIcon={<Iconify icon="carbon:launch" />}
          target="_blank"
          rel="noopener"
          href="https://app.funneloop.ai/register?utm_source=funneloop-website&utm_campaign=planner"
        >
          {'Use it for FREE!'}
        </Button>
      </Grid>
      <Grid item xs={12} md={7} sx={{ ml: 10 }}>
        <Stack
          spacing={5}
          //   justifyContent="center"
          alignItems={{ xs: 'center', md: 'flex-start' }}
          sx={{
            py: 10,
            textAlign: { xs: 'center', md: 'left', '@media (min-width:600px)': '1.9rem' },
          }}
        >
          <Typography variant="h1">
            {/* <Box component="span" sx={{ color: redColor }}> */}
            Calculate Your LTV in
            <Box component="span" sx={{ color: greenColor, fontWeight: 600 }}>
              {` 30 `}
            </Box>{' '}
            Seconds
            {/* </Box> */}
          </Typography>

          <Typography sx={{ color: 'text.secondary', fontSize: 22 }}>
            <Box component="span" sx={{ color: redColor, fontWeight: 600 }}>
              Don't waste{' '}
            </Box>
            time on Excel. <br />
            <Box component="span" sx={{ color: yellowColor, fontWeight: 600 }}>
              Effortlessly{' '}
            </Box>{' '}
            calculate LTV. <br />
            <Box component="span" sx={{ color: greenColor, fontWeight: 600 }}>
              Handle it all
            </Box>{' '}
            with FunneLoop!
            {/* <Box component="span" sx={{ color: greenColor, fontWeight: 600 }}>
              work for you.
            </Box> */}
          </Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}>Start saving money today!</Typography> */}
          <Stack spacing={2}>
            <Button
              style={{
                backgroundColor: greenColor,
                top: '140px',
              }}
              color="inherit"
              size="large"
              variant="contained"
              endIcon={<Iconify icon="carbon:launch" />}
              target="_blank"
              rel="noopener"
              href="https://app.funneloop.ai/register?utm_source=funneloop-website&utm_campaign=planner"
            >
              {'Use it for FREE!'}
            </Button>
            <Typography
              sx={{ color: 'text.secondary', top: 135, position: 'relative' }}
              variant="caption"
            >
              No credit card Needed.
            </Typography>
          </Stack>
        </Stack>

        {mdUp && (
          <ImageContainer>
            <Box display={'flex'} sx={{ position: 'absolute', top: '45%', left: '5%', zIndex: 0 }}>
              <img
                src={dashboardImageNew}
                alt="Budget Planner"
                width={700}
                style={{
                  transform: 'perspective(600px) rotate3D(1, -0.6, 0.4, 15deg)',
                  zIndex: 11,
                  // boxShadow: '0 0 40px 0 rgba(2, 3, 4, 0.3)',
                  //   mask: 'linear-gradient(135deg, #001c 40%, #020, #080c 60%) 100% 100%/ 240% 240%',
                }}
              />
            </Box>
            <Box
              display={'flex'}
              sx={{
                position: 'absolute',
                top: '45%',
                left: '5%',
                zIndex: 0,
                transform: 'perspective(600px) rotate3D(1, -0.6, 0.4, 15deg)',
                // backgroundColor: 'transparent',
                boxShadow: '0 0 50px 0 rgba(1, 3, 4, 0.3)',
                width: 700,
                height: 380,
              }}
            >
              {/* <img
                alt=""
                width={800}
                style={{
                  transform: 'perspective(800px) rotate3D(1, -0.6, 0.4, 15deg)',
                  zIndex: 21,
                  boxShadow: '1 1 50px 0 rgba(22, 177, 255, 0.9)',
                  //   mask: 'linear-gradient(135deg, #001c 40%, #020, #080c 60%) 100% 100%/ 240% 240%',
                }}
              /> */}
            </Box>
          </ImageContainer>
        )}
      </Grid>
    </Grid>
  );
}
