import { Grid, Button, Container, Typography } from '@mui/material';

const ht =
  '<html> <title>Online HTML Editor</title> <head> </head> <body> <p><span style="font-size:16px"><strong>Hubspot source:</strong></span></p> <h2>Prerequisites</h2> <ul> <li>HubSpot Account</li> </ul> <p><strong>Step 1&nbsp; - Authorize Funneloop to your Hubspot Account:</strong></p> <p><strong><img alt="" src="/assets/images/integration/hubspot_source_auth.png" style="height:319px; width:754px" /></strong></p> <ul> <li>Set a name for your hubspot source</li> <li>Use the date picker to select a start date and time for syncing data</li> <li>click the authorize button, you will be redirected to the authorize screen and chose the correct hubspot account</li> </ul> <p><strong>Step 2</strong> - <strong>Create the hubspot source</strong></p> <ul> <li>After you athorize Funneloop to access to Hubspot you will be redirected back to Funneloop app and the Save button will be enabled</li> <li>Click the save and wait until the source creation completes.</li> </ul> <p><img alt="" src="/assets/images/integration/hubspot_source_save.png" style="height:315px; width:753px" /></p> <p>&nbsp;</p> <p>&nbsp;</p> </body> </html>';

const HubspotSourcePage = () => {
  return (
    <Container
      sx={{ overflow: 'hidden', textAlign: 'left', pt: { xs: 5, md: 10 }, pb: { xs: 10, md: 15 } }}
    >
      <Grid container spacing={4}>
        <Grid item>
          <div dangerouslySetInnerHTML={{ __html: ht }}></div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HubspotSourcePage;
