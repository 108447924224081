import { useScroll } from 'framer-motion';
import { Helmet } from 'react-helmet-async';

import ScrollProgress from 'src/components/scroll-progress';

import HomeHero from './views/HomeHero';
import PlannerLanding from './views/PlannerLanding';

// ----------------------------------------------------------------------

const BudgetPlannerLanding = () => {
  const { scrollYProgress } = useScroll();

  return (
    <>
      <Helmet>
        <title>Funnel Loop website</title>
      </Helmet>

      <ScrollProgress scrollYProgress={scrollYProgress} />

      <PlannerLanding />
    </>
  );
};

export default BudgetPlannerLanding;
